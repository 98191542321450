<template>
     
  <!--<v-expansion-panel class="select-panel" expandable primary> -->
    <v-expansion-panel-content >      
      <div slot="header">{{title}}</div>
      <v-card>
        <v-card-text style="" class="select-card">
          <div v-for="(item, i) in items" :key="i">
            <v-checkbox class="select-checkbox" :class="{selected: selectedItems[i]}"
              v-model="selectedItems[i]"
              :label="item.title"
            >

            </v-checkbox>
          </div>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
  <!--</v-expansion-panel>-->
  
</template>

<script>
export default {
  components: {
  },
  data () { 
      return{
        selectedItems:[]
    }
  },
  props: [
      'items',
      'title',
      'selectedItemsOnStart'
  ],
  watch: {
    selectedItems: function() {
        this.updated();
    }
  },
  computed: {
    selected() {
      return this.items.filter((item, i) => {return this.selectedItems[i]});
    }
  },
  methods: {
    handleClick(item) {
      this.updated();
    },
    updated() {
      this.$emit("filterUpdated", this.selected);
    }
  },
  beforeMount() {
    if (this.selectedItemsOnStart) {
      this.selectedItemsOnStart.forEach(element => {
        this.selectedItems[this.items.findIndex(i => JSON.stringify(element) === JSON.stringify(i))] = true;
      });
    }    
  }
}
</script>

<style>
.select-card{
  max-height: 400px;
  overflow-y: auto;
}
.select-card .v-card__text{
  padding-bottom: 1em!important;
}
.select-checkbox{
  height: 3.3em;
  width:100%;
  padding:20px!important;
  margin:0!important;
}
.select-checkbox * {
  display: inline !important;
}
.select-checkbox.selected * {
  color:#ff4081!important;
  
}
.select-checkbox .v-input__slot{
  padding-top:1em;
  height:3em;
}
.select-checkbox .v-input__control{
  width: 100%;
  height: 3em;
}
.select-panel .v-expansion-panel__container{
  color:black;
  font-size: 16px;
  font-weight: 500;
}
.select-panel .v-expansion-panel__container--active .v-expansion-panel__header{
  background-color: #ff4081;
  color:white;
  font-size: 16px;
  font-weight: 500;
}
</style>
