<template>
    <v-container fluid>
        <v-layout row model-grid-list>

            <v-flex class="model-grid-list__item" v-for="(model, i) in models" :key="i">
                <modelcard :model="model"></modelcard>
            </v-flex>

            <v-flex xs12 v-if="showLoadMore">
                <div class="text-xs-center">
                    <v-btn @click="nextPage">Load more</v-btn>
                </div>
            </v-flex>

        </v-layout>
    </v-container>
  <!--v-infinite-scroll :loading="loading" @bottom="getMoreModels" style="max-height: 80vh; overflow-y: scroll;">

    </v-infinite-scroll-->
</template>
<script type="text/javascript">
import ModelService from "../mixins/ModelService";
import modelcard from './AggregateView/modelcard.vue'
export default {
    components: {
        modelcard
    },
    mixins: [ModelService],
    data: () => {
        return {
            loading: false,
            page: 1,
            showLoadMore: true
        };
    },

    computed: {
        filters() {
            return this.$store.getters.filters;
        },

        models() {
            let timestamp = Date.now().toString().slice(0, -4);
            let ret = this.$store.getters.models.slice(0);
            this.$store.getters.filters.forEach((filter) => {
                if (!filter.active) return true;
                ret = ret.filter((model) => filter.method(model, filter.value));
            });
            ret = ret.map((model) => {
                model.thumb += '?' + timestamp;
                return model;
            });
            return ret;
        }
    },
    methods: {
        nextPage() {
            this.getMoreModels(++this.page).then((res) => {
                this.showLoadMore = res;
            });
        },
        handleScroll() {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
            if (bottomOfWindow) {
                this.nextPage();
            }
        }
    },
    created() {
        this.$root.$on('filtersUpdated', () => {
            this.page = 1;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        });
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}

</script>

<style scoped>
    .model-grid-list {
        margin: 0 -20px;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .model-grid-list__item {
        padding:4px;
        flex-basis: 160px;
        flex-grow: 0;
        max-width: 160px;
    }

    @media screen and (max-width: 471px) {
        .model-grid-list__item {
            flex-basis: 45%;
            flex-grow: 0;
            max-width: 45%;
        }
    }
</style>
