<template>
    <v-app light>
        <!-- Left Drawer -->
        <v-navigation-drawer
            clipped
            app
            v-model="drawer"
        >
            <filters-component></filters-component>
        </v-navigation-drawer>
        <!-- /Left Drawer -->

        <topnavigation :keyword="search"></topnavigation>

        <v-content >
            <v-container fluid class="p-0" :class="{'nopadding': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm}">
                <v-slide-y-transition mode="out-in">

                    <aggregate-view></aggregate-view>

                </v-slide-y-transition>
            </v-container>
        </v-content>
        <v-footer app>
            <span>&copy; {{new Date().getFullYear()}}</span>
        </v-footer>
    </v-app>
</template>

<script>
import FiltersComponent from "./components/FiltersComponent.vue";
import AggregateView from "./components/AggregateView.vue";
import TopNavigation from "./components/TopNavigation.vue";
import FilterService from "./mixins/FilterService";
import ModelService from "./mixins/ModelService";

export default {
    mixins: [FilterService,ModelService],
    components: {
        FiltersComponent,
        'aggregate-view': AggregateView,
        'topnavigation': TopNavigation
    },
    props: ['search'],

    data () {
        return {
            drawer: false
        }
    },
    beforeMount() {
        this.setFilterFromParams();
    },
    mounted() {
        this.$root.$on('drawerToggle', data => {
            this.drawer = data ? data : !this.drawer;
        });

        this.getModels();
    }
}
</script>

<style scoped>
.nopadding {
    padding:0;
}
</style>
