<script type="text/javascript">

import FilterService from "../mixins/FilterService";
import ModelService from '../mixins/ModelService';
import FilterSelect from "./input/FilterSelect";

export default {
    mixins: [FilterService, ModelService],
    components: {
        'filterselect': FilterSelect
    },
    data(){
        return{
            platformsOnStart:[],
            gendersOnStart:[],
            agesOnStart:[]
        }
    },
    methods: {
        siteFilterUpdated(items) {
            this.selectedPlatforms = items;
            this.filterUpdated();
        },
        genderFilterUpdated(items) {
            this.selectedGenders = items;
            this.filterUpdated();
        },
        ageFilterUpdated(items) {
            this.selectedAge = items;
            this.filterUpdated();
        },
        filterUpdated() {
            let paramObj = this.urlParams(true);
            if (Object.entries(paramObj).length !== 0) {                
                this.$router.push({name: 'Search', query: paramObj});
            } else {                
                this.$router.push({name:'Home'});
            }
            this.getModels();
        }
    }
}
</script>

<template lang="html">
    <v-container class="container">
        <v-layout row wrap>
            <v-flex xs12>
            </v-flex>
        </v-layout>
        <v-layout row wrap>
            <v-flex xs12>
                <v-expansion-panel class="select-panel" expand primary>
                    <filterselect :items="$store.getters.platforms" title="Site" @filterUpdated="siteFilterUpdated" :selectedItemsOnStart="selectedPlatforms"></filterselect>
                    <filterselect :items="this.genders" title="Gender" @filterUpdated="genderFilterUpdated" :selectedItemsOnStart="selectedGenders"></filterselect>
                    <filterselect :items="this.ages" title="Age" @filterUpdated="ageFilterUpdated" :selectedItemsOnStart="selectedAge"></filterselect>
                </v-expansion-panel>
            </v-flex>
        </v-layout>
    </v-container>
</template>


<style scoped>
.container{
    padding:0;
}
</style>
