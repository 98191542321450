<template>
    <v-navigation-drawer :show="false"
            clipped
            app
            v-model="drawerChild"
        >
            <v-list-tile v-for="item in itemList" :key="item.title" :to="item.url">
                <v-list-tile-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>
    </v-navigation-drawer>
</template>


<script>

export default {
    components: {
        
    },
    props: [
'items',
'drawer',
'position',
'avatar'
],
data() {
return {
  drawerChild: false,
  itemList: []
}
},
mounted() {
this.itemList = this.items;
},
watch: {
  drawer (value) {
      this.drawerChild = value;
   },
   drawerChild (value) {
      this.$emit('drawerStatus', value)
   }
}
    
}
</script>
<style>

</style>
