<script>

export default {
  methods: {
    platformLogo(platformId) {
      return `https://storage.googleapis.com/files.divatraffic.com/img/Camsites_logos/ids/${platformId}.png`;
    }
  }
};
</script>
