<template>
    <v-card>
        <a class="profile-image" :href="getModelLink" target="_blank">
            <v-img :src="model.thumb" style="object-fit: cover; height: 100%; width: 100%" alt=""></v-img>
            <v-flex class="onlinetag online">
                <span>Online</span>
            </v-flex>
            <v-btn tag="a" :href="getModelLink" class="btn--seeOnline" target="_blank" small color="primary">See live</v-btn>
            <div class="model-card__siteLogo">
                <img :src="platformLogo(platformByName(model.platform).id)" :alt="model.platform">
            </div>
        </a>
        <v-card-text class="model-card__info">
            <h5 class="model-card__name">{{model.name}}</h5>
        </v-card-text>
    </v-card>
</template>

<script>

import { mapGetters } from "vuex";
import PlatformMixin from "../../mixins/PlatformMixin";

export default {
    name: 'AggregateView',
    mixins: [PlatformMixin],
    props: ['model'],
    methods: {
        loaded(model) {

        }
    },
    computed:{
        ...mapGetters(['platformByName']),
        getModelLink:function(){
            let aff = window.main._router.app._route.query["a"];
            if(aff!=null){
                let url = new URL(this.model.url);
                url.searchParams.set('a',aff);

                return url;
            }
            return this.model.url;
        }
    }
}
</script>

<style scoped>
    .onlinetag {
        position: absolute;
        top: 10px;
        right: 10px;
        text-align: center;
        padding: 5px;
        color: white;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
        font-size: 12px;
        border-radius: 2px;
    }
    .online {
        background-color: #67C18D;
    }
    .offline {
        background-color: #666666;
    }
    .profile-image {
        display: block;
        height: 140px;
        position: relative;
        overflow: hidden;
    }

    .model-card__info {
        padding: 2px;
    }

    .model-card__name {
        margin: 0;
        font-size: 12px;
        line-height: 18px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        height: 18px;
        white-space: nowrap;
        text-align: center;
    }

    .btn--seeOnline {
        position: absolute;
        left: 2px;
        bottom: 2px;
        padding: 0 5px;
        font-size: 12px;
        margin: 0;
        height: auto;
        min-width: auto;
    }

    .model-card__siteLogo {
        position: absolute;
        right: 2px;
        bottom: 2px;
        padding: 2px;
        background: #fff;
        display: flex;
        align-items: center;
    }

    .model-card__siteLogo img {
        width: 70px;
        height: auto;
    }

    .btn--seeOnline:hover {
        position: absolute;
        filter: brightness(85%);
    }
</style>
