<template>
    <v-flex>
        <v-navigation-drawer
            v-model="drawer"
        >
        </v-navigation-drawer>
        <!-- /Left Drawer -->

        <topnavigation :redirectQuery="'Home'"></topnavigation>

        <v-flex id="app" v-if="loaded">
            <v-app light>
                <v-content>
                    <v-container >
                        <v-layout row wrap >
                            <v-flex sm12>
                                <v-layout align-start justify-start row >
                                    <v-flex sm4 md2 lg1 >
                                        <v-avatar size="90%">
                                            <img class="img-circle elevation-7" :src="performer.performer_avatar">
                                        </v-avatar>
                                    </v-flex>
                                    <v-flex sm8 md10 lg11>
                                        <div class="headline font-weight-light">{{ performer.performer_identifier }}
                                            <v-btn flat icon color="light-blue darken-2" class="ma-0 pb-2">
                                                <v-icon>verified_user</v-icon>
                                            </v-btn>

                                        </div>
                                        <div class="subheading grey--text pt-0 pb-0">{{performer.profile_guestmessage ? performer.profile_guestmessage : 'Follow me to keep up with my private life :)'}}</div>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex xs12 lg9 height="100%">

                                <v-card>
                                    <v-layout>
                                        <v-responsive :aspect-ratio="16/9" >
                                            <div v-if="platform === 'BongaCams'" id="bongacamscontainer" style="width:100%; height:100%;" ref="bongacams">
                                            </div>
                                            <div class="xlovecamcontainer" v-if="platform === 'XLoveCams'" ref="xlovecams" id="xlovecams">
                                                <div id="domToInjectTheWidgetqwkjgdug37"></div>
                                            </div>
                                            <img :src="performer.performer_screenshot" style="object-fit: cover; z-index: 1; position:absolute; width: 100%; height: 100%" alt="" v-if="platform !== 'XLoveCams'">
                                            <iframe v-if="platform === 'Chaturbate'" :src="'https://chaturbate.com/in/?tour=SHBY&campaign=FCuis&track=embed&room='+performer.performer_identifier+'&bgcolor=white&embed_video_only=1'" scrolling="no" height="100%" width="100%" frameborder="0" style="z-index: 2; position: absolute"></iframe>
                                            <div ref="stream" style="width: 100%; z-index: 2; position: absolute" v-else-if="platform === 'LiveJasmin'">
                                                <div v-pre></div>
                                                <div id="object_container" class="jasmin-preview"></div>
                                            </div>
                                            <a @click="visit" style="position: absolute; height: 100%; width: 100%; display: block; top: 0; left: 0; z-index: 3" target="_blank"></a>
                                        </v-responsive>
                                    </v-layout>
                                    <v-tabs color="gray lighten-3" v-model="model">
                                        <v-tabs-slider color="pink darken-3"></v-tabs-slider>
                                        <v-tab xs4 href="#tab-1" ripple  v-if="social">
                                            <v-icon class="mr-1">account_box</v-icon> Social
                                        </v-tab>
                                        <v-tab xs4 href="#tab-2" ripple>
                                            <v-icon class="mr-1">chat</v-icon> Chat
                                        </v-tab>
                                        <v-tab xs4 href="#tab-3" ripple>
                                            <v-icon class="mr-1">info</v-icon> About
                                        </v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="model">
                                        <v-tab-item value="tab-1">
                                            <v-list class="pt-0 pb-0" v-if="social">
                                                <v-subheader style="justify-content: center">Add me on social media</v-subheader>
                                                <v-list-tile ripple @click="facebook" v-if="performer.user_facebook">
                                                    <v-list-tile-action>
                                                        <div class="soc mt-1 ml-1">
                                                            <a href="" class="soc-facebook" title="Facebook"></a>
                                                        </div>
                                                    </v-list-tile-action>

                                                    <v-list-tile-content>
                                                        <v-list-tile-title>{{performer.user_facebook}}</v-list-tile-title>
                                                    </v-list-tile-content>
                                                    <v-list-tile-action>

                                                        <v-icon>link</v-icon>
                                                    </v-list-tile-action>
                                                </v-list-tile>
                                                <v-divider></v-divider>
                                                <v-list-tile ripple @click="instagram" v-if="performer.user_instagram">
                                                    <v-list-tile-action>
                                                        <div class="soc mt-1 ml-1">
                                                            <a href="" class="soc-instagram" title="Instagram"></a>
                                                        </div>
                                                    </v-list-tile-action>

                                                    <v-list-tile-content>
                                                        <v-list-tile-title>@{{performer.user_instagram}}</v-list-tile-title>
                                                    </v-list-tile-content>

                                                    <v-list-tile-action>
                                                        <v-icon>link</v-icon>
                                                    </v-list-tile-action>
                                                </v-list-tile>
                                                <v-divider></v-divider>
                                                <v-list-tile ripple @click="twitter" v-if="performer.user_twitter">
                                                    <v-list-tile-action>
                                                        <div class="soc mt-1 ml-1">
                                                            <a href="" class="soc-twitter" title="Twitter"></a>
                                                        </div>
                                                    </v-list-tile-action>

                                                    <v-list-tile-content>
                                                        <v-list-tile-title>@{{performer.user_twitter}}</v-list-tile-title>
                                                    </v-list-tile-content>

                                                    <v-list-tile-action>
                                                        <v-icon>link</v-icon>
                                                    </v-list-tile-action>
                                                </v-list-tile>
                                            </v-list>
                                        </v-tab-item>
                                        <v-tab-item value="tab-2">
                                            <v-list class="pt-0 pb-0">
                                                <v-subheader style="justify-content: center">Come chat with me at:</v-subheader>
                                            </v-list>
                                            <div class="pa-3 text-center">
                                                <v-alert :value="true" color="warning" outline @click="visit" style="cursor: pointer">
                                                    <div class="pa-1">
                                                        <v-layout row wrap>
                                                            <v-flex class=text-xs-center>
                                                                <div><img :src="platformLogo(platformByName(performer.platform_name).id)" style="height: 45px" :alt="performer.platform_name"></div>
                                                                <p class="mb-0" style="font-size: 10px">Click here</p>
                                                            </v-flex>
                                                        </v-layout>
                                                    </div>
                                                </v-alert>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item value="tab-3">
                                            <v-list class="pt-0 pb-0">
                                                <v-subheader style="justify-content: center">Some info about me</v-subheader>
                                                <v-list-tile ripple v-if="gender">
                                                    <v-list-tile-action>
                                                        <v-icon>wc</v-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-content>Gender:</v-list-tile-content>
                                                    <v-list-tile-action>{{gender}}</v-list-tile-action>
                                                </v-list-tile>
                                                <v-list-tile ripple v-if="age">
                                                    <v-list-tile-action>
                                                        <v-icon>perm_contact_calendar</v-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-content>Age:</v-list-tile-content>
                                                    <v-list-tile-action>{{age}}</v-list-tile-action>
                                                </v-list-tile>
                                                <v-list-tile ripple v-if="performer.profile_country">
                                                    <v-list-tile-action>
                                                        <v-icon>language</v-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-content>Location:</v-list-tile-content>
                                                    <v-list-tile-action>{{performer.profile_country}}</v-list-tile-action>
                                                </v-list-tile>
                                                <v-list-tile ripple>
                                                    <v-list-tile-action>
                                                        <v-icon>ondemand_video</v-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-content>Camsite:</v-list-tile-content>
                                                    <v-list-tile-action>
                                                        <img :src="platformLogo(platformByName(performer.platform_name).id)" style="height: 25px" :alt="performer.platform_name">
                                                    </v-list-tile-action>
                                                </v-list-tile>
                                            </v-list>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card>


                            </v-flex>

                            <v-flex xs12 lg3 class="model-friends" :class="{'pt-3':  $vuetify.breakpoint.mdAndDown, 'pl-3': $vuetify.breakpoint.lgAndUp}">
                                <v-card height="100%">
                                    <v-subheader>
                                        Check out my friends
                                    </v-subheader>
                                    <div class="pa-1 diva_widget_container" >
                                        <div class="diva_widget" data-theme="theme1" data-size="m" data-width="100%" data-height="92%" :class="{ 'diva_widget_sidebar': $vuetify.breakpoint.lgAndUp}" ></div>
                                    </div>
                                </v-card>
                            </v-flex>

                        </v-layout>
                    </v-container>
                </v-content>
            </v-app>
        </v-flex>
    </v-flex>

</template>

<script>
import axios from 'axios'
import CONF from "../conf.js";
import Drawer from "./Drawer.vue";
import TopNavigation from "./TopNavigation.vue";
import { mapGetters } from "vuex";
import PlatformMixin from "../mixins/PlatformMixin";

export default {
    name: 'Profile',
    mixins: [PlatformMixin],
    components: {
        Drawer,
        'topnavigation': TopNavigation
    },
    props: ['camsite', 'profile'],
    data: () => {
        return {
            performer: {},
            countries: [],
            model: 'tab-1',
            loaded: false,
            platform: 'Chaturbate',
            drawer: null,
        };
    },
    computed: {
        ...mapGetters(['platformByName']),
        social() {
            return (this.performer.user_facebook || this.performer.user_instagram || this.performer.user_twitter);
        },
        gender() {
            return this.performer.profile_gender ? this.performer.profile_gender[0].toUpperCase() + this.performer.profile_gender.substring(1): this.performer.performer_gender[0].toUpperCase() + this.performer.performer_gender.substring(1);
        },
        age() {
            return (this.performer.profile_age ? this.performer.profile_age + ' years old' : this.performer.performer_age && this.performer.performer_age > 0 ? this.performer.performer_age + ' years old' : null)
        },
        click_url() {
            return 'http://test.diva.services' + this.performer.click_url;
        }
    },
    methods: {
        visit() {
            let platform = {};
            platform[this.performer.platform_name] = this.performer.performer_identifier;
            this.$metrika.params({Platform: platform});
            window.open('https://diva.services' + this.performer.click_url, '_blank')
        },
        twitter() {
            this.$metrika.params({
                Social: {
                    Twitter: this.performer.user_twitter
                }
            });
            window.open('https://twitter.com/' + this.performer.user_twitter, '_blank')
        },
        instagram() {
            this.$metrika.params({
                Social: {
                    Instagram: this.performer.user_instagram
                }
            });
            window.open('https://www.instagram.com/' + this.performer.user_instagram, '_blank')
        },
        facebook() {
            this.$metrika.params({
                Social: {
                    Facebook: this.performer.user_facebook
                }
            });
            window.open('https://www.facebook.com/' + this.performer.user_facebook, '_blank')
        },
        changeDrawerStatus(value) {
            this.Drawer = value;
        }
    },
    beforeUpdate() {
        if(this.platform === 'Chaturbate'){
            //chaturbate-iframe
        }
        else if (this.platform === 'LiveJasmin') {
            let preview = 'https://promo.awempire.com/live_feeds/script_basic_livefeed.php?performerId=' + this.performer.performer_identifier + '&performerList=&forcedPerformer=1&width=395px&height=240px&chatMode=none&bgColor=&site=jasmin&cobrandId=222866&psId=DivaTraffic&psTool=202_1&psProgram=revs&campaignId=&category=&muted=1&subAffId={click_id}';
            let livejasmin_script = document.createElement('script');
            livejasmin_script.setAttribute('src', preview);
            return document.head.appendChild(livejasmin_script);
        }
    },
    mounted() {
        let api_url = CONF.api.urls.profile.replace(/\{id\}/g, this.profile);
        if(this.$route.query.cid && this.$route.query.cid !== '{click_id}')api_url = api_url + '?cid=' + this.$route.query.cid;
        if(this.$route.query.oid && this.$route.query.oid !== '{order_id}')api_url = api_url + '&oid=' + this.$route.query.oid;
        if(this.$route.query.pid && this.$route.query.pid !== '{package_id}')api_url = api_url + '&pid=' + this.$route.query.pid;
        axios.get(api_url)
        .then(result => {
            this.performer = result.data;
            if (!this.social) {
                this.model = 'tab-2';
            }
            this.platform = this.performer.platform_name;
            window._diva = window._diva || [];
            var _diva = window._diva;
            _diva['setAccount'] = "'" + this.performer.owner_id ? this.performer.owner_id : this.performer.user_id + "'";
            _diva['Widget'] = [];
            _diva['Widget']['options'] = [];
            _diva['Widget']['options']['class'] = 'diva_widget';


            let diva = document.createElement('script');
            diva.setAttribute('src', '//diva.services/services?rnd=' + Math.random());
            document.head.appendChild(diva);
            this.loaded = true;

            if (this.platform === 'BongaCams') {
                let bonga = document.getElementById('bongacamscontainer');
                let bongaW = 9834759864567678;
                let bongaH = 9834759864567678;
                axios.get("https://promo-bc.com/promo.php?c=300599&type=embed_chat&model=" + this.performer.performer_identifier + "&stream_only_size="+ bongaW + "x" + bongaH)
                .then((response) => {
                    if(response.status === 200) {
                        let data = response.data.replace("document.write('",'').slice(0, -4).replace(/9834759864567678/g,'100%');
                        let bonga = document.getElementById('bongacamscontainer');
                        bonga.innerHTML = data;
                    }
                });
            }

            if (this.platform === 'XLoveCams') {
                let xlovecamsInlineScript = document.createElement('script');
                xlovecamsInlineScript.text = 'console.log("running xlovecams embedded");if (promotoolWidget === undefined) {var promotoolWidget = document.createElement("script");promotoolWidget.setAttribute("type", "text/javascript");promotoolWidget.setAttribute("src", "https://prm03.wlresources.com/static/js/app/widget.js");document.head.appendChild(promotoolWidget);}window.addEventListener("XlovepromotoolInit", function(event){var config = {"ui":{"id_affilie":"10086","cf":"ffffff","cc":"ffffff","ct":"000000","psm":"'+this.performer.performer_identifier+'","size":1,"tri":6},"domId":"domToInjectTheWidgetqwkjgdug37","resourcesUrl":"http://s4.wlresources.com","promotoolUrl":"https://prm03.wlresources.com"};Xlovepromotool.WidgetFactory.create("LiveWebcam", config).init();});';
                this.$refs.xlovecams.appendChild(xlovecamsInlineScript);
                let xlovecamScript = document.createElement('script');
                xlovecamScript.setAttribute('src', "https://www.xlovecam.com/js/ads.js");
                document.getElementById('xlovecams').appendChild(xlovecamScript);
            }

        });

    }
}


</script>
<style scoped>
    .soc a {
        border-radius: 4.5px;
        font-size: 16.5px;
        line-height: 30px;
        color: white;
        font-family: si!important;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow: hidden;
        width: 30px;
        height: 30px;
        -webkit-box-sizing: "border-box";
        -moz-box-sizing: "border-box";
        -ms-box-sizing: "border-box";
        -o-box-sizing: "border-box";
        box-sizing: "border-box";
        text-decoration: none;
        text-align: center;
        display: inline-block;
        zoom: 1;
    }
    .v-tabs__item {
        font-size: 10px;
    }
    .img-circle {
        width: 80px;
        height: 75px;
        margin: 0px auto 10px;
        border-radius: 50%;
    }
    .jasmin-preview {
        width: 100% !important;
    }
    .container{
        max-width: none;
    }
    .diva_widget_container{
        height:100%
    }
    .diva_widget_sidebar{
            display: contents;
            overflow: auto;
    }
    .v-toolbar{
        z-index: 999;
    }
    #domToInjectTheWidgetqwkjgdug37{
        height: -webkit-fill-available;
        width: -webkit-fill-available;
        position: absolute;
        display: block;
    }
    #domToInjectTheWidgetqwkjgdug37.lw *{
        height: -webkit-fill-available;
        width: -webkit-fill-available;
        position: absolute;
        display: block;
    }

</style>
