<template>
    <v-toolbar app clipped-left>
        <v-toolbar-title class="fill-height">
            <v-container class="fill-height pl-0">
                <v-layout align-center>
                    <v-btn flat icon @click="toggleDrawer()" dark color="pink"><v-icon>dehaze</v-icon></v-btn>
                    <a href="/"><img src="https://storage.googleapis.com/files.diva.network/img/diva.network-small.png" alt="diva.network" class="diva-logo hidden-sm-and-up diva-logo-small"></a>
                    <a href="/"><img  @click="goHome()" src="https://storage.googleapis.com/files.diva.network/img/diva.network.png" alt="diva.network" width="250" class="diva-logo hidden-xs-only"></a>
                </v-layout>
            </v-container>
        </v-toolbar-title>
        <v-toolbar-title class="hidden-sm-and-up full-width" :slot="searchSlot" align-center>
            <v-text-field class="search-input hidden-sm-and-up" outline v-model="search" ref="mobileSearch" append-icon="search" @keyup.enter="searchForKeyword" @click:append="searchForKeyword" @click:clear="clearSearch" clearable :rules="searchRules" required></v-text-field>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div></div>
        <v-text-field class="search-input hidden-xs-only" outline v-model="search" append-icon="search" @keyup.enter="searchForKeyword" @click:append="searchForKeyword" @click:clear="clearSearch" clearable :rules="searchRules" required></v-text-field>
        <v-btn class="hidden-sm-and-up" flat icon @click="toggleSearch()" dark color="pink"><v-icon >search</v-icon></v-btn>
    </v-toolbar>
</template>




<script>
import FilterService from "../mixins/FilterService";
import ModelService from '../mixins/ModelService';

export default {
    mixins: [FilterService, ModelService],
    props: ['keyword', 'redirectQuery'],
    data () {
        return {
            search: "",
            searchSlot: false,
            searchRules: [
                (v) => !v || (v && v.length >= 3 && v.length != 0) || 'Keywords must be 3 or more characters',
                (v) => !v || (v && v.length > 2 && !v.split(' ').find(word => word && word.length < 3)) || 'All keywords must be 3 or more characters'
            ]
        }
    },
    methods:{
        searchForKeyword() {
            if (this.search && !this.invalidKeyword()) {
                this.currentSearchWord = this.search.split(' ');
                let paramObj = this.urlParams(true);
                if (Object.entries(paramObj).length !== 0) {
                    this.$router.push({name: 'Search', query: paramObj});
                } else {
                    this.$router.push({name:'Home'});
                }
                this.getModels();
            }
            if (!this.search) {
                this.clearSearch();
            }
        },
        clearSearch() {
            this.$router.push({name:'Home'})
            this.currentSearchWord = "";
            this.getModels();
        },
        toggleDrawer() {
            this.$root.$emit('drawerToggle');
        },
        toggleSearch() {
            this.searchSlot = this.searchSlot ? false : 'extension';
            if (this.searchSlot) this.$nextTick(() => this.$refs.mobileSearch.focus());
        },
        invalidKeyword() {
            return this.search ? this.search.split(' ').find(word => word && word.length < 3) : false;
        }
    },
    mounted(){
        this.search = this.currentSearchWord;
        if (!this.search) {
            this.search = this.keyword;
        }
        if (this.search && !this.invalidKeyword()) {
            this.searchForKeyword();
        }
    }
}
</script>
<style>
.diva-logo-small {
    height: 40px;
    width: auto;
}
.diva-logo{
    width: auto;
    max-width: 250px;
    margin-right: 50px;
    margin-top:5px;
}
.search-input * {
    min-height: 0!important;
    max-height: 100px!important;
    margin-bottom:0 !important;
    margin-top:0 !important;
}
.search-input .v-input__append-inner {
    margin-top:8px!important
}
.search-input .v-text-field__details {
    display: flex!important;
}
.full-width{
    width:100%;
    margin:0 !important;
}
</style>
